<template>
  <div class="content">
    <van-nav-bar :title="'购买'+phoneNumber"></van-nav-bar>


    <van-cell>
      <h4>购买流程</h4>
      <p>1. 挑选号码。</p>
      <p>2. 下单付款。</p>
      <p>3. 上传开户资料以及证件照片。</p>
    </van-cell>

    <van-cell>
      <h4>购买说明</h4>
      <p>1. 由于手机号码特殊性以及唯一性，不确保所有号码都能成功购买。</p>
      <p>2. 下单之前最好打电话确认空号后，再付款。</p>
      <p>3. 开户前退单，全额退款。</p>
      <p>4. 由于我方原因导致开户失败，全额退款。</p>
      <p>5. 开户成功后，客户原因退单的不退款。</p>
    </van-cell>

     <van-cell>
      <h4>号码详情</h4>
      <div v-if="phone != null">
        <p>号码：<span class="red">{{phone.phoneNumber}}</span></p>
        <p>运营商：<span>{{phone.optName}}</span></p>
        <p>归属地：<span>{{phone.province}} {{phone.area}}</span></p>
        <p>
            价格：<span class="red">{{0>=phone.salePrice+phone.fee?0:phone.salePrice+phone.fee}}</span> 元 （服务费 <span class="red">{{0>=phone.salePrice?0:phone.salePrice}}</span> 元 + 话费 <span class="red">{{phone.fee}}</span> 元） 
        </p>
        <p>包含话费：<span class="red">{{phone.fee}}</span> 元</p>
        <p>合约期：<span class="red">{{phone.onlineMonths}}</span> 月</p>
        <p>介绍：<span>{{phone.introduce}}</span></p>
      </div>
      <div v-else>
        号码找不到了呢
      </div>
    </van-cell>

    <van-cell style="margin-bottom: 76px;" v-if="phone != null">
      <h4>付款信息</h4>
      <!-- <p><img width="20%" src="../assets/alipay_logo.png"/></p> -->
      <!-- <p><img width="20%" src="../assets/wx_logo.png"/></p> -->

      <van-cell v-if="couponInfo != '暂无优惠券可用'" title="优惠券"  is-link @click="openCoupon()" :value="couponInfo"/>
      <van-cell v-if="couponInfo == '暂无优惠券可用'" title="优惠券"  :value="couponInfo"/>
   
      <p>支付金额：
        <span class="red" style="font-size: 20px">
          {{price}}
        </span> 元
        <span v-if="discountPrice != null" style="font-size: 12px; color:red">已优惠{{discountPrice}}元</span>
      </p>
      <van-goods-action-button style="width: 95%;" type="danger" text="提 交 订 单" @click="buy(phone)" />
    </van-cell>

    <!-- 优惠券弹出层 -->
    <van-popup v-if="coupons.length != 0" v-model="showCoupon" closeable position="bottom" :style="{ height: '90%' }">
      <van-nav-bar title="我的优惠券" />
      <div v-for="item in coupons" :key="'mycoupon_'+item.id" style="width:95%;max-width: 800px;margin: 0 auto;">
        
        <div :class="phone.salePrice >= item.meetAmount ? 'coupon' : 'coupon_use'">
          <section class="coupon_price">
            ￥{{item.discountAmount}}
          </section>
          <section class="coupon_desc">
            <h4>{{item.name}}</h4>
            <span>有效期：{{item.liveDay}}~{{item.deadDay}}</span><br/>
            <span>使用说明：优惠券只能抵扣服务费部分，服务费满{{item.meetAmount}}使用，一单仅限使用一张。仅限在有效期内使用，过期作废。</span>
          </section>    
        </div>
        
        <section style="float:left;margin-top:50px;margin-left:5px">
          <van-radio v-if="phone.salePrice < item.meetAmount" :disabled="true" v-model="useCoupon" :name="item"></van-radio>
          <van-radio v-else v-model="useCoupon" :name="item" @click="chgCoupon()"></van-radio>
        </section>  
        <section style="float:left;font-size: 14px;margin-left:5px;color:#ED3908" v-if="phone.salePrice < item.meetAmount">不满足使用条件</section>
      </div>
      


      <van-goods-action-button style="width:80%;margin-left: 10%;margin-bottom: 20px;"  type="warning" text="不 使 用 优 惠 券" @click="cancelCoupon()" />

        
        
    </van-popup>
      
    



    <BottomNav @change-cartNum="cartNum=$event"></BottomNav>
  </div>
</template>

<script>

import {Dialog} from 'vant';
// import { Toast } from 'vant';
import BottomNav from '../components/BottomNav'

export default {
  components: {
      BottomNav
  },
  data(){
    return {
      phoneNumber: null,
      phone: null,
      coupons: [],
      useCoupon: null,
      showCoupon: false,
      couponInfo: "暂无优惠券可用",
      // 支付金额
      price: 0,
      // 优惠金额
      discountPrice: null
    };
  },
  methods: {
    // 不使用优惠
    cancelCoupon(){
      this.showCoupon = false;
      this.useCoupon = null;
      this.couponInfo = '不使用优惠';
      this.price = this.phone.salePrice + this.phone.fee;
      this.discountPrice = null;
    },
    // 修改使用其他优惠
    chgCoupon(){
      this.showCoupon = false;
      this.couponInfo = '-'+this.useCoupon.discountAmount+' 元';
      this.price = this.phone.salePrice + this.phone.fee - this.useCoupon.discountAmount;
      this.discountPrice = this.useCoupon.discountAmount;
    },
    // 打开优惠券层
    openCoupon(){
      if(this.coupons.length != 0){
        this.showCoupon = true;
      }
    },
    loadData(){
      let url = '/customer/main/phoneNumber/detail';
      let params = {params : {phoneNumber: this.phoneNumber}};
      this.$axios.get(url, params).then(resp => {
        if(resp.data.status == this.STATUS_SUCCESS){
          this.phone = resp.data.data;
          // 查询可用优惠券
          url = '/customer/main/phoneNumber/coupon-canuse'
          this.$axios.get(url).then(resp => {
            if(resp.data.list!=null && resp.data.list.length != 1){
              this.coupons = resp.data.list;
              this.price = this.phone.salePrice + this.phone.fee;
              for(let i=0;i<this.coupons.length;i++){
                if(this.coupons[i].meetAmount <= this.phone.salePrice){
                  this.useCoupon = this.coupons[i];
                  this.couponInfo = '-'+this.useCoupon.discountAmount+' 元';
                  this.price = this.phone.salePrice + this.phone.fee - this.useCoupon.discountAmount;
                  this.discountPrice = this.useCoupon.discountAmount;
                  break;
                }
              }
              
            }
          });
        }else{
          Dialog({message: resp.data.message});
        }
      });
    },
    buy(phone){
      Dialog.confirm({message: "确定要提交订单吗？"}).then(()=>{
        let url = '/customer/main/order/submit-order';
        let couponId = this.useCoupon == null ? null : this.useCoupon.id;
        let params = {phoneNumber: phone.phoneNumber, price: this.price, couponId: couponId};
        this.$axios.post(url, params).then(resp => {
          if(resp.data.status == this.STATUS_SUCCESS){
            let orderId = resp.data.data;
            this.initCartNum();
            this.$router.push("/order?order="+orderId);
          }else{
            Dialog({message: resp.data.message});
            setTimeout(function(){
              window.location.reload();// 刷新页面
            }, 2000)
            
          }
        });
      });  
    },
    initCartNum(){
      // 查询购物车中添加的号码数量
      let url = "/customer/main/cart/my-cart-num";
      this.$axios.get(url).then(resp => {
        this.$emit("change-cartNum", resp.data.data); 
      });
    }
    
   
  },
  mounted: function () {
    this.phoneNumber = this.$route.query.phoneNumber;
    if(this.phoneNumber == null || !/^1[3456789]\d{9}$/.test(this.phoneNumber)){
      this.$router.push("/");
      return;
    }
    this.loadData();
  },
 
}
</script>


<style scoped>
  p{
    text-indent: 20px;
  }
  .red{
    color: #ED3908;
  }
  .blue{
    color: #039BE5;
  }
  .deltext{
    text-decoration: line-through;
  }
.coupon{
    width: 90%;
    height: 120px;
    margin: 3px auto;
    border-radius: 10px ;
    border: 1px solid #cccccc;
    background: linear-gradient(to right,  #ff5a24,#ff6034);
    color: #ffffff;
    font-family: "微软雅黑";
    float: left;
  }
  .coupon_use{
    width: 90%;
    height: 120px;
    margin: 3px auto;
    border-radius: 10px ;
    border: 1px solid #cccccc;
    background: linear-gradient(to right,  #cccccc,#999999);
    color: #ffffff;
    font-family: "微软雅黑";
    float: left;
  }
  .coupon_price{
    width: 25%;
    height: 120px;
    line-height: 120px;
    font-size: 30px;
    text-align: center;
    float: left;
  }
  .coupon_desc{
    width: 75%;
    height: 120px;
    float: left;  
  }
  .coupon_desc h4{
    padding-left: 20%;
    font-weight: 900;
    margin: 5px auto;
    font-size: 16px;
  }
  .coupon_desc span{
    font-size: 12px;
  }



</style>
