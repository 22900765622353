import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import axios from 'axios'
import VueWechatTitle from "vue-wechat-title";




import 'vant/lib/index.css';



axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  if(response.data.status == "1004"){
    Dialog({message: "会话超时，请重新登录或者打开首页链接" });
    location.href = '/login';
  }else if(response.data.status == "0000"){
    return response;
  }else{
    Dialog({message: response.data.message});
    // 对响应错误做点什么
    return Promise.reject(response);
  }
}, function (error) {
  Dialog({message: "网络不稳定，请稍后重试" });
  // 对响应错误做点什么
  return Promise.reject(error);
});





const app = createApp(App)
app.use(router)
app.use(VueWechatTitle)



import {Button, Popup, Form, Field, Cascader, Checkbox, Dialog, CellGroup, Search, Tab , Tabs, NavBar, Switch, Collapse, CollapseItem, ActionBarButton
,ActionBar, Grid, GridItem,Picker, List, RadioGroup, Radio, Loading, Icon, Tabbar, TabbarItem, ActionSheet ,Cell, DatetimePicker,NoticeBar   } from 'vant';

app.use(Button).use(Popup).use(Form).use(Field).use(Cascader).use(Checkbox).use(Dialog).use(CellGroup).use(Search ).use(Tab).use(Tabs).use(NavBar ).use(Switch ).use(Collapse).use(CollapseItem)
    .use(ActionBarButton).use(ActionBar).use(GridItem).use(Grid).use(Picker ).use(List ).use(RadioGroup).use(Radio).use(Loading).use(Icon).use(Tabbar).use(TabbarItem)
    .use(ActionSheet).use(Cell).use(DatetimePicker  ).use(NoticeBar)


app.mount('#app')
// 定义全局变量
app.config.globalProperties.$axios = axios;
// 服务端返回的执行成功的状态码
app.config.globalProperties.SUCCESS = '0000';



