<template>
  <div class="content">


    <van-row>
      <van-nav-bar title="我的推广链接" left-arrow @click-left="goBack()"/>
      <van-col span="24" >
        <p class="aditem">
          <a :href="link">特惠套餐推广链接</a>
          <van-button type="primary"
                      size="small"
                      @click="copyTelcomStar59"
          >复制</van-button>
        </p>
      </van-col>

    </van-row>
   

    <BottomNav @change-cartNum="cartNum=$event"></BottomNav>



  </div>
</template>

<script>



import BottomNav from 'components/BottomNav'
import VueClipboard from "vue-clipboard3";
import { Toast} from "vant";

const { toClipboard } = VueClipboard();

export default {
  components: {
      BottomNav
  },
  data(){
    return {
      customer: {},
      link: ''
    }
  },

  methods: {
   
    goBack(){
      this.$router.go(-1);
    },
    myinfo(){
      let url = '/customer/main/myinfo';
      this.$axios.post(url).then(resp => {
        if(resp.data.status == this.SUCCESS){
          this.customer = resp.data.data;
          if (this.customer.type == 1){
            this.link = 'https://m.52haoma.com/ad-web/proxy/'+this.customer.account;
          }else {
            this.link = 'https://m.52haoma.com/ad-web/special/'+this.customer.account;
          }
          
        }else{
          Toast(resp.data.message);
        }
      });
    },
    copyTelcomStar59(){
      // const  {clipboard} = VueClipboard();
      this.copy(this.link);
      Toast("下单链接复制成功");
    },


    copy(Msg){
      try {
        //复制
        toClipboard(Msg)
        console.log(Msg)
        //下面可以设置复制成功的提示框等操作
        //...
      } catch (e) {
        //复制失败
        console.error(e)
      }
    }


  },
 
  mounted: function () {
    this.myinfo();
  },
};







</script>

<style>
  .aditem{
    font-size: 0.25rem;
    border-bottom: 0.01rem solid #c8c9cc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.15rem 0.2rem;
  }


</style>