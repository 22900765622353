import { createRouter, createWebHistory } from "vue-router";

import Login from 'views/Login'
import Login_ from 'views/Login-'

import Index from 'views/Index'

import MyCart from 'views/MyCart'
import MyOrder from 'views/MyOrder'
import Buy from 'views/Buy'
import OrderStep from 'views/OrderStep'
// 我
import Me from 'views/me/Index'
// 个人信息
import MyInfo from 'views/me/MyInfo'
// 我的优惠券
import Coupon from 'views/me/Coupon'
// 修改密码
import EditPasswd from 'views/me/EditPasswd'
// 我的推广链接
import MyLink from "../views/me/MyLink";

import UnicomGXOrder from "../views/UnicomGXOrder";



const routes = [
    {
        path: '/',
        component: Index
    },
    {
        path: '/login',
        component: Login
    },

    {
        path: '/mycart',
        component: MyCart
    },
    {
        path: '/myorder',
        component: MyOrder
    },
    {
        path: '/buy',
        component: Buy
    },
    {
        path: '/order',
        component: OrderStep
    },
    {
        path: '/llcardorder',
        component: UnicomGXOrder
    },
    {
        path: '/me',
        component: Me
    },
    {
        path: '/myinfo',
        component: MyInfo
    },
    {
        path: '/mylink',
        component: MyLink
    },
    {
        path: '/coupon',
        component: Coupon
    },
    {
        path: '/editPasswd',
        component: EditPasswd

    },
    {
        path: '/:customer',
        component: Login_

    }

];

const router = createRouter({
    history: createWebHistory('/'),
    routes
});

export default router;


