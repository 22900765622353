<template>
    <div class="content">

        <van-row>
            <van-col span="24">
                <section class="search_type">
                    <span :class="searchType == 1 ? 'typeselected':''" @click="searchType = 1">模糊搜索</span>
                    <span :class="searchType == 2 ? 'typeselected':''" @click="searchType = 2">按位搜索</span>
                </section>
            </van-col>

        </van-row>

        <van-row v-if="searchType == 1">
            <van-col span="24">
                <section class="search_like" >
                    <van-search v-model.trim="queryObj.phoneLike" placeholder="请输入号码关键词"
                                shape="round" style="width: 4rem"/>
                    <div style="display:flex;align-items: center;">
                        <span>任意&nbsp;</span>
                        <van-switch v-model="queryObj.trail" size="0.24rem"/>
                        <span>&nbsp;尾号</span>
                    </div>
                </section>
            </van-col>
        </van-row>
        <van-row v-if="searchType == 2">
            <van-col span="24">
                <section class="search_position">

                    <input
                            v-model="nums[item]"
                            maxlength="1"
                            v-for="item in [0,1,2,3,4,5,6,7,8,9,10]"
                            :key="'p'+item"
                            :ref="'num'+item"
                            @keyup="nextFocus(item)"
                    />
                </section>
            </van-col>
        </van-row>


        <van-row >
            <van-col span="24">
                <section class="search_excludenum">
                    <label>不含数字</label>
                    <van-checkbox
                            v-model="excludeNums[index]"
                            shape="square"
                            v-for="(item, index) in excludeNum"
                            :name="item"
                            :key="'exclude_'+item">{{item}}</van-checkbox>



                </section>
            </van-col>
        </van-row>

        <van-row >
            <van-col span="24">
                <section>
                    <van-action-bar>
                        <van-action-bar-button type="primary" text="搜索号码" @click="loadData(1)" :disabled="disableQuery"/>
                        <van-action-bar-button type="warning" text="重置条件" @click="clearCondition()"/>
                    </van-action-bar>
                </section>
            </van-col>
        </van-row>



        <van-grid>
            <van-grid-item icon="phone-circle-o" :text="optName" @click="showOpt = true"/>
            <van-grid-item icon="location-o" :text="homeLoc" @click="showHomeLoc = true"/>
            <van-grid-item icon="apps-o" :text="rule" @click="showRules = true"/>
            <van-grid-item icon="balance-o" :text="price" @click="showPrice = true"/>
        </van-grid>



        <div style="margin-top:0.1rem;margin-bottom: 1.56rem">

            <van-list  v-model="loading" :finished="finished" :finished-text="result" @load="loadData">

                <article class="phone_number_article">

                    <section v-for="phone in searchPhones"
                             :key="phone.phoneNumber"
                             class="phoneNumberSec"
                             @click="showPhoneDetail(phone)">
                        <span class="phone_number_span" v-html="phone.display"></span>
                        <span class="area_span">
                            {{phone.province}}  {{phone.area}}
                        </span>
                        <span class="price_opt_span">
                            <span  v-if="phone.optId == 1">中国移动</span>
                            <span  v-else-if="phone.optId == 2">中国联通</span>
                            <span  v-else-if="phone.optId == 3">中国电信</span>
                            <span  v-else-if="phone.optId == 4">虚拟运营商</span>

                            <span class="red" style="font-weight: bold"  v-if="phone.costPrice != null">￥{{phone.costPrice+phone.advanceFee}}</span>
                            <span class="red" style="font-weight: bold" v-else>￥{{phone.salePrice+phone.advanceFee}}</span>
                        </span>




                    </section>

                </article>




            </van-list>

        </div>


        <!-- 点击号码 查看号码详情 弹窗 开始 -->
        <van-popup v-model:show="showDetail" closeable >
            <article class="phone_number_detail_article">
                <div class="flexClass">
                    <h3 v-html="phone.display" style="font-size: 0.36rem; font-weight: bold;"></h3>
                    <van-button type="primary" style="height: auto;margin-left:3%;" @click="updChangePrice(phone.phoneNumber,phone.salePrice,phone.costP)">改价</van-button>
                </div>
                <div>
                    <span  v-if="phone.optId == 1">中国移动</span>
                    <span  v-else-if="phone.optId == 2">中国联通</span>
                    <span  v-else-if="phone.optId == 3">中国电信</span>
                    <span  v-else-if="phone.optId == 4">虚拟运营商</span>
                    &nbsp;&nbsp;&nbsp;

                    <span class="area_span"> {{phone.province}} {{phone.area}}</span>

                </div>

                <br/>


                <!--     线下渠道               -->
                <div v-if="phone.costPrice != null">
                    <div class="lsep"></div>
                    <article class="phone_detail">
                        <section>
                            <span>建议零售价</span>
                            <span><span class="red">{{phone.costPrice+phone.advanceFee}}</span> 元</span>
                        </section>
                        <div class="vsep"></div>
                        <section>
                            <span>结算底价</span>
                            <span><span class="red">{{phone.salePrice+phone.advanceFee}}</span> 元</span>
                        </section>
                        <div class="vsep"></div>
                        <section>
                            <span>包含话费</span>
                            <span><span class="red">{{phone.advanceFee}}</span> 元</span>
                        </section>

                        <div class="vsep"></div>
                        <section>
                            <span>预估利润</span>
                            <span>
                                        <span class="red" v-if="phone.costPrice-phone.salePrice >= 100">{{phone.costPrice-phone.salePrice}}</span>
                                        <span class="red" v-else>100</span>
                                    元</span>
                        </section>
                    </article>

                    <div class="lsep" style="margin-bottom: 0.1rem;"></div>

                    <p style="margin: 0.1rem auto;">
                        <label>开户说明：</label>{{phone.introduce}}</p>

                    <p v-if="phone.salePrice + phone.advanceFee < 0" class="red" >
                        <label>结算说明：</label>结算底价为负数的，客户激活后次月在网并且不停机的情况下，统一做返佣，部分会按月分批次返佣，详情咨询客服。
                    </p>

                    <!--                            <van-action-bar>-->
                    <!--                                <van-action-bar-button type="warning" text="加入购物车" />-->
                    <!--                                <van-action-bar-button type="danger" text="立即购买" />-->
                    <!--                            </van-action-bar>-->
                    <!--                            <div class="van-sku-actions">-->
                    <!--                                <button class="van-button van-button&#45;&#45;warning van-button&#45;&#45;large"-->
                    <!--                                        @click="addCart(phone)">-->
                    <!--                                    <div class="van-button__content"><span class="van-button__text">加入购物车</span></div>-->
                    <!--                                </button>-->
                    <!--                                <button class="van-button van-button&#45;&#45;danger van-button&#45;&#45;large" @click="buy(phone)">-->
                    <!--                                    <div class="van-button__content"><span class="van-button__text">立即购买</span></div>-->
                    <!--                                </button>-->
                    <!--                            </div>-->

                </div>
                <div v-else>
                    <div class="lsep"></div>
                    <article class="phone_detail">
                        <section>
                            <span>结算底价</span>
                            <span><span class="red">{{phone.salePrice+phone.advanceFee}}</span> 元</span>
                        </section>
                        <div class="vsep"></div>
                        <section>
                            <span>包含话费</span>
                            <span><span class="red">{{phone.advanceFee}}</span> 元</span>
                        </section>

                    </article>

                    <div class="lsep" style="margin-bottom: 0.1rem;"></div>

                    <p style="margin: 0.1rem auto;">
                        <label>开户说明：</label>{{phone.introduce}}
                    </p>

                    <p v-if="phone.salePrice + phone.advanceFee < 0" class="red" >
                        <label>结算说明：</label>结算底价为负数的，客户激活后次月在网并且不停机的情况下，统一做返佣，部分会按月分批次返佣，详情咨询客服。
                    </p>
                </div>

            </article>


        </van-popup>
        <!-- 点击号码 查看号码详情 弹窗 结束 -->


        <BottomNav @change-cartNum="cartNum=$event"></BottomNav>
        <!--运营商-->
        <van-popup v-model:show="showOpt" position="bottom">
            <van-picker
                    title="请选择运营商"
                    :columns="opts"
                    @confirm="confirmOpt"
                    @cancel="showOpt = false"/>
        </van-popup>

        <!--归属地-->
        <van-popup v-model:show="showHomeLoc" position="bottom">
            <van-picker
                    title="请选择号码归属地"
                    :columns="pro_cities"
                    @confirm="confirmHomeLoc"
                    @cancel="showHomeLoc = false"
                    :style="{ height: '30%' }"/>
        </van-popup>

        <!--规律-->
        <van-popup v-model:show="showRules" position="bottom">
            <van-picker
                    title="请选择号码特征"
                    :columns="rules"
                    @confirm="confirmRule"
                    @cancel="showRules = false"
                    :style="{ height: '30%' }"/>
        </van-popup>

        <!--价格-->
        <van-popup v-model:show="showPrice" position="bottom" style="font-size: 0.28rem;">
            <section class="condtitle">
                <span class="btn" @click="showPrice = false">取消</span>
                <span>请选择价格区间</span>
                <span class="btn" @click="confirmPrice">确定</span>
            </section>

            <section class="priceSec">
                <div v-if="customerType == 2">
                    <van-button type="primary">建议零售价</van-button>

                    <div class="priceItem">
                        <span v-for="(item, index) in costPrices" @click="queryObj.costPrice = index" :class="queryObj.costPrice ==index ? 'itemSelected':''" :key="'costPrice'+index">{{item}}</span>
                    </div>
                </div>

                <div>
                    <van-button type="primary">结算底价</van-button>

                    <div class="priceItem">
                        <span v-for="(item, index) in salePrices" @click="queryObj.salePrice = index" :class="queryObj.salePrice ==index ? 'itemSelected':''" :key="'salePrice_'+index">{{item}}</span>
                    </div>

                </div>

                <div>
                    <van-button type="primary">预存款</van-button>

                    <div class="priceItem">
                        <span v-for="(item, index) in advanceFees" @click="queryObj.advanceFee = index" :class="queryObj.advanceFee ==index ? 'itemSelected':''" :key="'advanceFee_'+index">{{item}}</span>
                    </div>
                </div>

            </section>
        </van-popup>

        <!--改价弹窗-->
        <van-popup v-model:show="showChangePrice" position="bottom" :style="{ height: '50%' }">
            <div class="order_div">
                <div class="subject1">
                    <div class="liang_phonenumber1">{{ changePrice.phoneNumber }}</div>
                    <div class="text-area1"></div>
                </div>
                <div class="subject_b1">
                    <div class="service_fee1">
                        <span class="text_a1">批发服务费</span>
                        <span class="price1">{{ changePrice.salePrice }}元</span>
                    </div>
                    <div class="service_fee1 border1">
                        <span class="text_a1">零售服务费</span>
                        <span class="price1">{{ changePrice.costPrice }}元</span>
                    </div>
                </div>
                <div class="subject2">
                    <van-notice-bar
                            wrapable
                            :scrollable="false"
                            :text="changePriceRange"
                    />
                </div>


                <van-form @submit="onChangePrice">
                    <van-cell-group inset>
                        <van-field
                                v-model="updObj.changePrice"
                                type="tel"
                                name="改价金额"
                                label="改价金额"
                                placeholder="请输入改价金额(元)"
                                :rules="[{ required: true, message: '请输入改价金额(元)' }]"
                        />

                        <van-field
                                v-model="updObj.changeTime"
                                label="改价时长"
                                name="请输入改价时长(分钟)"
                                placeholder="请输入改价时长(分钟)"
                                :rules="[{ required: true, message: '请输入改价时长(分钟)' }]"
                        />

                    </van-cell-group>
                    <div style="margin: 0.16rem;margin-bottom:0.5rem;">
                        <van-button round block type="primary" native-type="submit" color="#4fc08d">
                            改价
                        </van-button>
                    </div>
                </van-form>
            </div>
        </van-popup>





    </div>

</template>

<script>

    import {Dialog} from 'vant'
    import {Toast} from 'vant';


    import BottomNav from '../components/BottomNav'
    import provinceCities from '../assets/data/homeloc.json'
    import rules from '../assets/data/rules.json'



    export default {
        components: {
            BottomNav
        },
        data() {

            return {
                activeName: "",
                // 搜索类型 1-模糊搜索  2-按位搜索
                searchType: 1,
                // 查询按钮 状态
                disableQuery: false,
                // 模糊搜索关键字
                phoneKey: "",
                // 省市
                pro_cities: provinceCities,
                rules: rules,
                showOpt: false,
                showHomeLoc: false,
                showRules: false,
                showPrice: false,
                showChangePrice:false,//改价弹窗
                showDetail: false,
                changePrice:{},//改价靓号对象
                phone: {},
                changePriceRange:"",//改价区间
                updObj: {},//改价对象-axios传入后端的参数

                opts: [{value: null, text: "运营商"}, {value: "2", text: "中国联通"}, {value: "3", text: "中国电信"},
                       {value: "1",text: "中国移动"}, {value: "4", text: "虚拟运营商"}],
                // 底价
                salePrices: ['全部','<0元','0元','1元~100元','101元~200元','201元~500元','501元~1000元','1001元~2000元','2001元~5000元','5001元以上'],
                // 零售价
                costPrices: ['全部','0元','1元','2元~100元','101元~200元','201元~500元','501元~1000元','1001元~2000元','2001元~5000元','5001元以上'],
                // 预存款
                advanceFees: ['全部','0元','1元~100元','101元~200元','201元~500元','501元~1000元','1001元~2000元','2001元~5000元','5001元以上'],
                homeLoc: "归属地",
                optName: "运营商",
                rule: '特征',
                price: '价格',
                // 查询条件
                queryObj:{},
                customerType: sessionStorage.getItem("type"),





                // 数量列表
                numberNums: [{value: null, text: "数量"}, {value: 7, text: "7个"}, {value: 6, text: "6个"}, {
                    value: 5,
                    text: "5个"
                }, {value: 4, text: "4个"}],
                // 数字列表
                numbers: [{value: null, text: "数字"}, {value: 9, text: "9"}, {value: 8, text: "8"}, {
                    value: 7,
                    text: "7"
                }, {value: 6, text: "6"}, {value: 5, text: "5"}, {value: 4, text: "4"}, {
                    value: 3,
                    text: "3"
                }, {value: 2, text: "2"}, {value: 1, text: "1"}],



                searchPhones: [], //搜索号码结果
                currentPage: 1, //当前页码
                loading: false,
                nums: [1, null, null, null, null, null, null, null, null, null,null], // 号码位数
                result: "",
                finished: false,
                focus: [true, false, false, false, false, false, false, false, false, false],
                excludeNum: [2, 3, 4, 5, 6, 7, 8, 9, 0],
                excludeNums:[false, false, false, false, false,false,false,false,false],
                moreNum: null
            };
        },
        methods: {
            //改价
            updChangePrice(phoneNumber,salePrice,costP){

                //隐藏查价界面
                this.showDetail = false;

                //赋值
                this.changePrice.phoneNumber = phoneNumber;
                this.changePrice.salePrice = salePrice;
                this.changePrice.costPrice = costP;
                if (salePrice>0){
                    this.changePriceRange = "改价区间："+ salePrice +"元~"+costP+"元";
                }else{
                    this.changePriceRange = "改价区间：1元~"+costP+"元";
                }

                //显示改价界面
                this.showChangePrice = true;
            },
            // 验证改价靓号的合法性
            phoneValidator(val) {
                if (!/^1[3456789]\d{9}$/.test(val)) {
                    return "请填写正确的改价靓号";
                }
            },
            nextFocus(item) {
                if (item == 1) {
                    if (!/[3456789]$/.test(this.nums[item])) {
                        this.nums[item] = "";
                        return;
                    }
                }

                if (item != 10 && /\d/.test(this.nums[item])) {
                    // this.nums[item+1] = "";
                    this.$refs['num' + (item + 1)][0].focus();
                }
            },

            // 确定运营商
            confirmOpt(value){
                this.optName = value.text;
                this.queryObj.optId = value.value;
                this.showOpt = false;
                this.currentPage = 1;
                this.loadData();
            },
            // 确定归属地
            confirmHomeLoc(value){
                let province = value[0].text;
                let area = value[1].text;
                if(province == '全国'){
                    province = "";
                    area = "";
                }else if(area == '全部'){
                    area = "";
                }
                this.queryObj.province = province;
                this.queryObj.area = area;
                if(area != ''){
                    this.homeLoc = area;
                }else if(province != ''){
                    this.homeLoc = province;
                }else{
                    this.homeLoc = '归属地';
                }
                this.currentPage = 1;
                this.showHomeLoc = false;
                this.loadData();
            },
            // 确定规律
            confirmRule(value){
                let pType = value[0].text;
                let aType = value[1].text;
                if(pType == '主规律'){
                    pType = "";
                }else if(aType == '子规律'){
                    aType = "";
                }
                this.queryObj.pType = pType;
                this.queryObj.aType = aType;
                if(aType != ''){
                    this.rule = aType;
                }else if(pType != ''){
                    this.rule = pType;
                }else{
                    this.rule = '特征';
                }
                this.currentPage = 1;
                this.showRules = false;
                this.loadData();
            },
            // 确定价格区间
            confirmPrice(){
              let salePrice =  this.queryObj.salePrice;
              let costPrice = this.queryObj.costPrice;
              let advanceFee = this.queryObj.advanceFee;
              if(
                  (!salePrice && !costPrice && !advanceFee)
              ){
                  this.price = "价格";
              }else{
                  this.price = "价格筛选...";
              }
              this.currentPage = 1;
              this.showPrice = false;
              this.loadData();
            },
            // 清除查询条件
            clearCondition(){
                this.excludeNums =  [false, false, false, false, false,false,false,false,false];
                this.nums = [1,null,null,null,null,null,null,null,null,null,null];
                this.loadData(1);
                this.optName = "运营商";
                this.localName = "归属地";
                this.rule = "特征";
                this.price = "价格";
            },

            showPhoneDetail(phone){
                this.phone = phone;
                this.showDetail = true;
            },



            loadData(pageNum) {
                // 如果上一次还没加载完成，停止下一次加载
                if(this.loading){
                    return;
                }

                this.disableQuery = true;
                this.loading = true;
                this.result = "";



                let url = "/customer/main/phoneNumber/query";
                if(pageNum){
                    this.currentPage = pageNum;
                }

                if(this.currentPage == 1){
                    this.searchPhones = [];
                }

                this.queryObj.pageNum = this.currentPage;
                // 模糊搜索
                if(this.searchType == 1){
                    this.queryObj.phonePosition = null;
                }else{
                    this.queryObj.phoneLike = null;
                    let text = "";
                    for(let i=0;i<this.nums.length;i++){
                        if(this.nums[i]){
                            text += this.nums[i];
                        }else{
                            text += "_";
                        }
                    }
                    this.queryObj.phonePosition = text;

                }
                // 不含数字
                this.queryObj.excludeNums = [];
                for(let i=0;i<this.excludeNums.length;i++){
                    if(this.excludeNums[i]){
                        this.queryObj.excludeNums.push(this.excludeNum[i]);
                    }
                }


                this.$axios.post(url, this.queryObj).then(resp => {
                   if(resp.data.status == this.SUCCESS){
                       let list = resp.data.list;
                       if (list.length == 0) {
                           if (this.currentPage == 1) {
                               this.result = "没有符合您搜索条件的号码，换个条件试试";
                           } else {
                               this.result = "没有更多了，我是有底线的";
                           }
                           this.finished = true;
                       } else {
                           for (let i = 0; i < list.length; i++) {
                               this.searchPhones.push(list[i]);
                           }
                           if (list.length < 40) {
                               this.result = "没有更多了，我是有底线的";
                               this.finished = true;
                           } else {
                               this.currentPage++;
                           }
                       }
                   }else{
                       Dialog({message: resp.data.message });
                   }



                    this.loading = false;
                    this.disableQuery = false;
                });
            },

            addCart(phone) {
                let url = "/customer/main/cart/add";
                let params = {phoneNumber: phone.phoneNumber};
                this.$axios.post(url, params).then(resp => {
                    if (resp.data.status == this.STATUS_SUCCESS) {
                        Toast("加入购物车成功");
                        // 更新购物车数量
                        this.initCartNum();
                    } else {
                        Dialog({message: resp.data.message});
                    }


                });
            },
            // 立即购买
            buy(phone) {
                this.$router.push({path: "/buy", query: {phoneNumber: phone.phoneNumber}});
            },
            openUrl(url) {
                window.location.href = url;
            },

            initCartNum() {
                // 查询购物车中添加的号码数量
                let url = "/customer/main/cart/my-cart-num";
                this.$axios.get(url).then(resp => {
                    this.$emit("change-cartNum", resp.data.data);
                });
            },
            // 去后台改价
            onChangePrice(){
                this.updObj.phoneNumber = this.changePrice.phoneNumber;
                let url = "/customer/main/phoneNumber/upd-changePrice"
                this.$axios.post(url, this.updObj).then(resp => {
                    if(resp.data.status == this.SUCCESS){
                        Dialog({message: "改价成功" });
                        //清空改价弹窗数据
                        this.updObj = {};
                        //关闭改价弹窗
                        this.showChangePrice=false;
                    }else{
                        Dialog({message: resp.data.message });
                    }
                });
            }
        },
        mounted: function () {

            // let url = "/customer/location";
            // this.$axios.get(url).then(resp => {
            //   let province = "浙江省";
            //   let city = "杭州市";
            //   if(resp.data.status == this.STATUS_SUCCESS){
            //     province = resp.data.data.province;
            //     city = resp.data.data.city;
            //   }
            //   a: for(let i=0;i<provinces.length;i++){
            //     if(province.indexOf(provinces[i].text) != -1){
            //       // 修改省份
            //       this.province = provinces[i].text;
            //       b: for(let i=0;i<pro_cities.length;i++){
            //         for(let j in pro_cities[i]){
            //           if(j == this.province){
            //             this.cities = pro_cities[i][j];
            //             this.cities.splice(0,0, {"text": "归属城市", "value": null});
            //             break b;
            //           }
            //         }
            //       }

            //       for(let j=0;j<this.cities.length;j++){
            //         if(city.indexOf(this.cities[j].text) != -1){
            //           this.city = this.cities[j].text;
            //           break a;
            //         }
            //       }
            //     }
            //   }
            //   this.optId = "2";
            //   this.selectSearch();
            // });

           // this.loadData();
            // 初始化购物车数量
            // this.initCartNum();


        },

    }
</script>


<style>
    .phone_number_article{
        width: 7.5rem;
        margin: 0.05rem auto;
        display: flex;
        flex-wrap: wrap;
        background: #f3f4f7;
    }

    .phone_number_article section{
        width: 3.55rem;
        display: flex;
        flex-wrap: wrap;
        padding: 0.05rem;
        margin-left: 0.08rem;
        margin-top: 0.08rem;
        margin-bottom: 0.08rem;
        text-align: center;
        background: #ffffff;

    }
    .phone_number_span{
        font-size: 0.35rem;
        width: 3.55rem;
        text-align: center;
        font-weight: bold;
    }
    .area_span{
        width: 3.55rem;
        text-align: center;
        color: #888888;
        font-size: 0.25rem;
        margin: 0.05rem auto;
    }
    .price_opt_span{
        width: 3.55rem;
        display: flex;
        justify-content: space-around;
        font-size: 0.3rem;
    }
    .phone_number_detail_article{
        width: 6.5rem;
        margin: 0 auto;
        height: auto;
        padding: 0.5rem;

    }



    .btn{
        width: 1.0rem;
        text-align: center;
    }
    .condtitle{
        height: 0.6rem;
        line-height: 0.6rem;
        display: flex;
        justify-content: space-around;
        border-bottom: 0.01rem solid #c8c9cc;
        margin-bottom: 0.06rem;
    }
    .search_excludenum{
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }
    .search_excludenum label{
        margin-left: 0.08rem;
        margin-right: 0.05rem;
    }



    .priceSec{
        width: 7.1rem;
        margin: 0 auto;
        padding: 0.2rem;
    }
    .priceItem{
        margin: 0.1rem auto;
        display: flex;
        flex-wrap: wrap;
    }
    .itemSelected{
        background: #ed3908;
        color: #ffffff;
    }

    .priceItem span{
        padding: 0.05rem 0.15rem;
        border: 0.01rem solid #ed3908;
        margin-left: 0.08rem;
        margin-top: 0.08rem;
    }

    .phone_detail{
        display: flex;
        justify-content: space-around;

    }
    .phone_detail section{
        width: 3.5rem;
        text-align: center;
        display: flex;
        flex-direction: column;
    }

    .vsep{
        border-left: 0.01rem solid #c8c9cc;
    }
    .lsep{
        border-top: 0.01rem solid #c8c9cc;
    }

    .van-cell__title {
        display: flex;

        align-items: center;
    }

    .van-cell__title label{
        text-align: center;
    }

    .van-action-bar{
        position: static !important;
    }



    .search_type {
        width: 7.5rem;
        margin: 0.15rem auto;
        text-align: center;
        display: flex;
        justify-content: center;

    }

    .search_type span {
        width: 3.5rem;
        height: 0.45rem;
        line-height: 0.45rem;
        border: 0.01rem solid #ff4c4c;
    }

    .typeselected {
        background: #ff4c4c;
        color: #ffffff;
    }


    .search_position, .search_like {
        width: 7.5rem;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-around;
        margin: 0.1rem auto;
        align-items: center;
        height: 0.8rem;
    }

    .search_position input {
        width: 0.5rem;
        height: 0.5rem;
        text-align: center;
    }

    .likePhoneNumber {
        width: 4.6rem;
        height: 0.4rem;

    }
    .flexClass{
        display: -webkit-flex; /* Safari */
        display: flex;

    }
    .liang_phonenumber1 {
        font-size: 0.6rem;
        text-align: center;
        line-height: 0.8rem;
        width: 100%;
        color: #ffffff;
        font-weight: bold;
        margin: 0.2rem auto;
    }
    .text-area1 {
        width: 100%;
    }
    .subject_b1 {
        width: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    .service_fee1 {
        width: 45%;
        height: 0.8rem;
        margin-top: 0.2rem;
    }
    .text_a1 {
        display: block;
        width: 100%;
        height: 40%;
        font-size: 0.3rem;
        text-align: center;
    }
    .price1 {
        display: block;
        width: 100%;
        height: 40%;
        font-size: 0.3rem;
        text-align: center;
        color: #F5A54B;
        margin: 0.1rem auto;
    }
    .subject1 {
        width: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        background-color: #CB0101;
    }
    .subject2{
        width: 100%;
        margin: 0.2rem auto;
    }
    .order_div{
        width: 6.9rem;
        margin: 0.3rem;
        background: #ffffff;
        font-size: 0.25rem !important;
    }

</style>
