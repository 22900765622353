<template>
  <div class="content">
    <van-nav-bar :title="title"></van-nav-bar>


    <van-cell v-if="order == null">
      <p>没有找到这个订单</p>  
    </van-cell>
    <van-cell v-else-if="order.phoneNumber != null">

      <!-- 扫一扫付款 -->
      <div v-if="order.order.status == 20">
        <h4>付款</h4>
        <p>
          打开<span class="red">支付宝</span>扫描下面二维码付款,请在<span class="red">{{leftTime}}</span>分钟内完成支付，否则订单将会关闭。
        </p>
        <p>付款金额：<span>{{order.tradeRecord.totalAmount}}</span>元</p>
        <p>
          <img width="30%" :src="order.tradeRecord.qrcodeUrl"/>
        </p>
        
      </div>
      <!-- 上传证件照片 -->
      <div v-if="order.order.status == 22">
        <h4>上传购卡人证件照片</h4>
        <p>
          根据国家对通信产品<span class="red">实名信息登记</span>的要求，您需要为号码<span class="red">{{order.phoneNumber.phoneNumber}}</span>上传居民身份证正反面照片和正面免冠照片，我们将严格保障您的信息安全
        </p>
        <p>
          <img width="100%" :src="frontSrc"/>
          <van-uploader style="width: 100%" :after-read="readFront" :max-count="1">
            <van-button round block type="info" native-type="submit" > 上传身份证正面照片 </van-button>
          </van-uploader>
        </p>
        <p>
          <img width="100%" :src="backSrc" />
          <van-uploader style="width: 100%" :after-read="readBack" :max-count="1">
            <van-button round block type="info" native-type="submit"> 上传身份证背面照片 </van-button>
          </van-uploader>
          
        </p>
        <p>
          <img width="100%" :src="nowrapSrc"/>
          
          <van-uploader style="width: 100%" :after-read="readNowrap" :max-count="1">
            <van-button round block type="info" native-type="submit"> 上传正面免冠照片 </van-button>
          </van-uploader>
        </p>

        <van-button round block type="danger" native-type="submit" @click="doneOrder()"> 完 成 订 单 </van-button>
      </div>
      <!-- 填写购买人资料 -->
      <div v-else-if="order.order.status == 21">
        <h4>填写购卡人资料</h4>
        <p>
          <van-form @submit="submitInfo">
            <van-field v-model.trim="info.userName" name="姓名" label="姓名" placeholder="请输入购买人姓名" :rules="[{required: true}]" />
            <van-field v-model.trim="info.phone"  name="联系电话" label="联系电话" placeholder="请输入购买人联系电话" :rules="[{pattern: phonePattern,  required: true, message: '请输入正确的联系电话'}]"/>
            <van-field v-model.trim="info.idCard"  name="身份证号" label="身份证号" placeholder="请输入购买人身份证号码" :rules="[{validator: checkIdcard, required: true, message: '请输入正确的身份证号码'}]"/>
            <van-field readonly clickable name="area" :value="value"  label="收件地址" placeholder="点击选择省市区" :rules="[{ required: true}]"  @click="showArea = true"/>
            <van-popup v-model="showArea" position="bottom" >
              <van-area :area-list="areaList" @confirm="onConfirm" @cancel="showArea = false" />
            </van-popup>
            <van-field v-model.trim="info.address"  name="详细地址" label="详细地址" placeholder="请输入详细收货地址" :rules="[{ required: true}]"/>
            <div style="margin: 16px;">
              <van-button round block type="info" native-type="submit"> 提 交 资 料 </van-button>
            </div>
          </van-form>
        </p>
      </div>
      <div v-else>
        <h4>订单信息</h4>
        <p>订单状态：
          <span class="red"  v-if="order.order.status == 23">待下单</span>
          <span class="red" v-else-if="order.order.status == 24">已下单</span>
          <span v-else-if="order.order.status == 20">未付款</span>
          <span v-else-if="order.order.status == 21">待填写资料</span>
          <span v-else-if="order.order.status == 22">待上传证件照</span>
          <span class="red" v-else-if="order.order.status == -21">超时关闭</span>
          <span class="red" v-else-if="order.order.status == -20">退单退款</span>
          <span class="red" v-else-if="order.order.status == -11">退单不退款</span>
        </p>
        
        <p>创建时间：<span class="red">{{order.order.createTime}}</span></p>
      </div>

      <div v-if="order.info != null">
        <div v-if="order.info.expressComponey != null">
          <h4>物流信息</h4>
          <p>{{order.info.expressComponey}} {{order.info.expressNo}}</p>
          
        </div> 
        <h4>购卡人资料</h4>
        <p>姓名：<span class="red">{{order.info.userName}}</span></p>
        <p>联系电话：<span class="red">{{order.info.phone}}</span></p>
        <p>身份证号：<span class="red">{{order.info.idCard}}</span></p>
        <p>收件地址：<span class="red">{{order.info.province}}{{order.info.city}}{{order.info.area}}{{order.info.address}}</span></p>

            
      </div> 

      <h4>号码详情</h4>
      <p>号码：<span class="red">{{order.phoneNumber.phoneNumber}}</span></p>
      <p>运营商：<span class="red">{{order.phoneNumber.optName}}</span></p>
      <p>归属地：<span class="red">{{order.phoneNumber.province}} {{order.order.area}}</span></p>
      <p>价格：<span class="red">{{order.phoneNumber.salePrice + order.phoneNumber.fee}}</span>元
        （服务费 <span class="red">{{order.phoneNumber.salePrice}}</span> 元 + 话费 <span class="red">{{order.phoneNumber.fee}}</span> 元） 
      </p>
      <p>实际支付：<span class="red">{{order.order.price}}</span> 元 
        <span v-if="order.order.discountAmount != null" style="color:#ED3908; font-size: 12px;">（已优惠{{order.order.discountAmount}}元）</span>
      </p>
      <p>包含话费：<span class="red">{{order.phoneNumber.fee}}</span> 元</p>
      <p>合约期：<span class="red">{{order.phoneNumber.onlineMonths}}</span> 月</p>
      <p>介绍：<span class="red">{{order.phoneNumber.introduce}}</span></p>
  
      <div style="height: 67px;"></div>

      <BottomNav @change-cartNum="cartNum=$event"></BottomNav>
    </van-cell>
  </div>
</template>

<script>

import {Dialog} from 'vant';
import { Toast } from 'vant';
import area from '../assets/js/area.js'
import frontImg from '../assets/idcard1.jpg'
import backImg from '../assets/idcard2.jpg'
import nowrapImg from '../assets/idcard3.jpg'
import idcard from '../assets/js/idcard.js'

import BottomNav from '../components/BottomNav'

export default {
  components: {
      BottomNav
  },
  data(){
    return {
      order: {
       
      },
      info: {},
      orderId: null,
      areaList: area,
      value: null,
      showArea: false,
      frontSrc: frontImg,
      backSrc: backImg,
      nowrapSrc: nowrapImg,
      // 上传的照片对象
      uploadForm: new FormData(),
      photoList: [null, null, null],
      leftTime: "15:00",
      title: "订单",
      phonePattern: /^1[3-9]\d{9}$/,
      


    };
  },
  methods: {
    checkIdcard(val){
      return idcard(val);
    },
    
    loadData(){
      let url = '/customer/main/order/query';
      let params = {order: this.orderId};
      this.$axios.post(url, params).then(resp => {
        if(resp.data.status == this.STATUS_SUCCESS){
          this.order = resp.data.data;
          
          if(this.order != null){
            this.title = "号码"+this.order.order.phoneNumber+"订单";
            // 未支付
            if(this.order.order.status == 20){
               // 开启倒计时模式
              this.startReserveTime();
               //  查询支付宝支付结果。。。。。。。。
              let vue = this;
              setTimeout(function(){
                vue.queryPayResult();
              }, 2000)
            }
           
          }
        }else{
          Dialog({message: resp.data.message});
        }
      });
    },
    onConfirm(values){
      this.info.province = values[0].name;
      this.info.city = values[1].name;
      this.info.area = values[2].name;
      this.value = values[0].name+"/"+values[1].name+"/"+values[2].name;
      this.showArea = false;
    },
    submitInfo(){
      Dialog.confirm({message: "确定要提交资料吗？"}).then(()=>{
        this.info.order = this.orderId;
        let url = '/customer/main/order/submit-order-info';
        this.$axios.post(url, this.info).then(resp => {
          if(resp.data.status == this.STATUS_SUCCESS){
            Toast("资料提交成功");
            
          }else{
            Dialog({message: resp.data.message});
          }
          setTimeout(function(){
            window.location.reload();// 刷新页面
          }, 1000);
        });
      })
    },
    readFront(file){
      // 读取正面照片
      this.frontSrc = file.content;
      this.photoList[0] = file.file;
    },
    readBack(file){
      // 读取背面照片
      this.backSrc = file.content;
      this.photoList[1] = file.file;
    },
    readNowrap(file){
      // 读取免冠照片
      this.nowrapSrc = file.content;
      this.photoList[2] = file.file;
    },
    // 点击完成订单按钮
    doneOrder(){
      if(this.photoList[0] == null){
        Toast("请上传身份证正面照片");
        return;
      }
      if(this.photoList[1] == null){
        Toast("请上传身份证背面照片");
        return;
      }
      if(this.photoList[2] == null){
        Toast("请上传正面免冠照片");
        return;
      }
      Dialog.confirm({message: "确定要上传照片完成订单吗？"}).then(()=>{
        for(let i=0;i<this.photoList.length;i++){
          this.uploadForm.append("photos", this.photoList[i]);
        }

        this.uploadForm.append("order", this.orderId);
        let url = '/customer/main/order/submit-photos';
        this.$axios.post(url, this.uploadForm).then(resp => {
          if (resp.data.status == this.STATUS_SUCCESS) {
            Toast("订单已经创建完成！"); 
          } else {
            Dialog({message: resp.data.message});
          }
          setTimeout(function(){
            window.location.reload();
          }, 1000)
          
        }) ;


      });
    },
    // 倒计时
    startReserveTime(){
      let leftTime = this.order.leftTime;
      // 剩余分钟
      let minutes = parseInt(leftTime/1000/60);
      // 剩余秒数
      let seconds = parseInt((leftTime-minutes*60*1000)/1000);

      minutes = minutes<10?"0"+minutes:minutes;
      seconds = seconds<10?"0"+seconds:seconds;
      this.leftTime = minutes+":"+seconds;

      this.order.leftTime -= 1000;
      if(this.order.leftTime < 0){
        // 请求服务端要求关闭订单
        let url = '/customer/main/order/cancel-order';
        let params = {order: this.orderId}
        this.$axios.post(url, params).then(resp => {
          if(resp.data.status == this.STATUS_SUCCESS){
            setTimeout(function(){window.location.reload()}, 1000)
          }else{
            Dialog({message: resp.data.message});
          }
        });


      }else{
        let vue = this;
        // 继续计时
        setTimeout(function(){
          vue.startReserveTime();
        }, 1000);
      }

    },

    queryPayResult(){
      let url = '/customer/main/order/query-alipay-result';
      let params = {order: this.orderId}
      this.$axios.post(url, params).then(resp => {
          if(resp.data.status == this.STATUS_SUCCESS){
            if(resp.data.data){
              setTimeout(function(){window.location.reload()}, 1000);
            }else{
              let vue = this;
              // 每隔3s查询支付结果
              setTimeout(function(){
                vue.queryPayResult();
              }, 3000);
            }
          }else{
            Dialog({message: resp.data.message});
          }
        });
    }
    
   
  },
  mounted: function () {
    this.orderId = encodeURIComponent(this.$route.query.order);
    this.loadData();
    
  },
 
}
</script>


<style >
  p{
    text-indent: 20px;
  }
  .red{
    color: #ED3908;
  }
  .blue{
    color: #039BE5;
  }
  .van-uploader__wrapper{
    display: block !important;
  }




</style>
