<template>
  <div class="content">
    <van-nav-bar title="兰兰卡订单"></van-nav-bar>

<!--    <van-tabbar v-model="active" :fixed="false" @change="chgStatus">-->
<!--      <van-tabbar-item icon="bag-o" :name="null">全部</van-tabbar-item>-->
<!--      <van-tabbar-item icon="smile-o">成功</van-tabbar-item>-->
<!--      <van-tabbar-item icon="warning-o">失败</van-tabbar-item>-->
<!--      <van-tabbar-item icon="flower-o">激活</van-tabbar-item>-->
<!--    </van-tabbar>-->

    <div class="queryCond">
<!--      <van-field v-model="queryObj.phoneNumber" type="tel" clearable  placeholder="请输入订购号码" style="width: 3.5rem"/>-->
      <van-field v-model="queryObj.name"  placeholder="请输入客户姓名" clearable style="width: 3.5rem"/>
      <van-field v-model="queryObj.phone"  type="tel" clearable  placeholder="请输入客户联系电话" style="width: 3.5rem"/>
      <van-field v-model="statusText"  readonly  placeholder="请选择订单状态"  @click="showStatus = true" style="width: 3.5rem"/>
      <van-field v-model="queryObj.startDay"  readonly  placeholder="请选择开始日期"  @click="showStartDay = true" style="width: 3rem"/>
      -
      <van-field v-model="queryObj.endDay"  readonly  placeholder="请选择结束日期"  @click="showEndDay = true" style="width: 3rem"/>
      <input type="hidden" v-model="queryObj.status"/>




      <van-button type="primary" size="small" @click="loadData(1)">查询</van-button>
    </div>



    <van-cell>
      <!-- 订单列表 -->
      <van-list style="margin-bottom: 1.56rem;"  v-model="loading" :finished="finished" :finished-text="result" @load="loadData">

        <van-collapse v-model="activeName" accordion >
          <van-collapse-item  v-for="order in myorders" :key="order.id" :name="order.id+'_'+order.type" >

            <template #title>

              <label style="width: 1.8rem">
                {{order.createTime.substring(0,10)}}
              </label>

              <label style="width: 1.8rem">
                {{order.createTime.substring(0,10)}}
              </label>

              <label style="width: 1rem">
                <span class="green" v-if="order.status == 2">激活</span>
                <span class="red" v-else-if="order.status == -1">退单</span>
                <span class="blue" v-else-if="order.expressNo != null">已发货</span>
                <span  v-else>待处理</span>




              </label>
            </template>
            <div>
              <div>

                <p><label>创建时间：</label><span class="red">{{order.createTime.substring(0,19)}}</span></p>
                <p><label>客户姓名：</label><span class="red">{{order.name}}</span></p>
                <p><label>联系电话：</label><span class="red">{{order.phone}}</span></p>
                <p><label>收件地址：</label><span class="red">{{order.province}} {{order.city}} {{order.district}} {{order.address}}</span></p>
                <p><label>物流信息：</label><span class="red">{{order.expressCompany}} {{order.expressNo}} </span></p>
                <p v-if="order.remark != null">说明：<span class="red">{{order.remark}} </span></p>
                <p>产品名称：<span class="red">{{order.type==1?'29元100GB+30分钟通话大流量卡':''}} </span></p>


              </div>
            </div>
          </van-collapse-item>
        </van-collapse>
        
      </van-list>

    </van-cell>
 


    <BottomNav @change-cartNum="cartNum=$event"></BottomNav>


    <van-popup v-model:show="showStatus" position="bottom">
      <van-picker
              title="订单状态"
              :columns="statuses"
              @confirm="confirmStatus"
              @cancel="showStatus = false"
              :style="{ height: '30%' }"/>
    </van-popup>
<!--起始日期 -->
    <van-popup v-model:show="showStartDay" position="bottom">
      <!---->
      <van-datetime-picker v-model="startDay" type="date" title="选择起始年月日" :min-date="minDate" :max-date="today"  @confirm="confirmStartDay" @cancel="cancelStartDay" cancel-button-text="清空"/>
    </van-popup>

    <!-- 结束日期  -->
    <van-popup v-model:show="showEndDay" position="bottom">
      <!---->
      <van-datetime-picker v-model="endDay" type="date" title="选择起始年月日" :min-date="minDate"  :max-date="today" @confirm="confirmEndDay" @cancel="cancelEndDay" cancel-button-text="清空"/>
    </van-popup>



  </div>
</template>

<script>

// import {Dialog} from 'vant';
import { Toast } from 'vant';
import BottomNav from '../components/BottomNav'

export default {
  components: {
      BottomNav
  },
  data(){
    return {
      result: "",
      finished: false,
      myorders: [], // 我的购物车中的商品
      currentPage: 1, // 当前页
      loading: false,
      activeName: "",
      queryObj: {pageNum: 1},
      active: 0,
      showStatus: false,
      showStartDay: false,
      showEndDay: false,
      statusText: '',
      today: new Date(),
      startDay: new Date(),
      endDay: new Date(),
      statuses: [
          {value: '-1', text: "全部"},
          {value: '1', text: "待处理"},
          {value: '2', text: "已发货"},
          {value: '3', text: "激活"},
          {value: '4', text: "退单"}
          ]
    };
  },
  computed: {
    // 最早日期是90天前
    minDate(){
      // 返回1970年1月1日到现在的时间毫秒数
      let time = new Date().getTime()-3*30*24*60*60*1000;
      let a = new Date(time);
      return a;
    }
  },
  methods: {

    loadData(pageNum){
      // 如果上一次还没加载完成，停止下一次加载
      if(this.loading){
        return;
      }
      this.loading = true;

      if(pageNum){
        this.currentPage = pageNum;
      }

      if(this.currentPage == 1){
        this.myorders = [];
      }
      let url = "/customer/main/unicomGxOrder/select";
      this.queryObj.pageNum = this.currentPage;

      this.$axios.post(url, this.queryObj).then(resp=>{
        if(resp.data.status == this.SUCCESS){
          let list = resp.data.list;

          if(!list || list.length == 0){
            if(this.currentPage == 1){
              this.result = "您没有符合条件的订单";
            }else{
              this.result = '仅展示近90天内的订单，更早订单请咨询客服';
            }
            this.finished = true;
          }else{
            for(let i=0;i<list.length;i++){
              this.myorders.push(list[i]);
            }
            if(list.length < 40){
              this.result = '仅展示近90天内的订单，更早订单请咨询客服';
              this.finished = true;
            }else{
               this.currentPage ++;
            }

            this.loading = false;
          }
        }else{
          this.finished = true;
          Toast(resp.data.message);
        }
        this.loading = false;
      });
    },

    confirmStatus(val){
      if(val.value == -1){
        this.statusText = '';
      }else{
        this.statusText = val.text;
      }
      this.queryObj.status = val.value;
      this.showStatus = false;
      this.loadData(1);
    },
    // 确认起始日期
    confirmStartDay(val){
      this.queryObj.startDay = this.formatDate(val);
      this.showStartDay = false;

      this.loadData(1);
    },
    // 确认截止日期
    confirmEndDay(val){
      this.queryObj.endDay = this.formatDate(val);
      this.showEndDay = false;
      this.loadData(1);
    },
    // 清空起始日期
    cancelStartDay(){
      this.queryObj.startDay = null;
      this.showStartDay = false;
      this.loadData(1);
    },
    // 清空截止日期
    cancelEndDay(){
      this.queryObj.endDay = null;
      this.showEndDay = false;
      this.loadData(1);
    },

    formatDate(date){
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      return year+"-"+(month<10?'0'+month:month)+"-"+(day<10?'0'+day:day);
    },

    stopOpen: function(e){
      e.stopPropagation()
    },


   
  },
  mounted: function () {

    // // 查询购物车中添加的号码数量
    // let url = "/customer/main/cart/my-cart-num";
    // this.$axios.get(url).then(resp => {
    //   this.$emit("change-cartNum", resp.data.data);
    //   // this.loadData();
    // });

    
    this.loadData(1);



  },
 
}
</script>


<style scoped>
  p{
    text-indent: 0.2rem;
  }

  .queryCond{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }






</style>
