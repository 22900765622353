<template>
  <div class="content">


    <van-row>
      <van-nav-bar title="我的优惠券" left-arrow @click-left="goBack()"/>
      <van-tabbar v-model="coupon.status" :fixed="false" @change="chgStatus">
        <van-tabbar-item icon="balance-list-o" :name="0">可用</van-tabbar-item>
        <van-tabbar-item icon="completed" :name="1">已使用</van-tabbar-item>
        <van-tabbar-item icon="failure" :name="-1">已过期</van-tabbar-item>

      </van-tabbar>
     <van-list style="margin-bottom: 76px;"  v-model="loading" :finished="finished" :finished-text="result" @load="mycoupon">
       <div v-for="item in coupons" :key="'coupon_'+item.id">
          <div :class="item.status == 0 ? 'coupon' : 'coupon_use'">
            <section class="coupon_price">
              ￥{{item.discountAmount}}
            </section>
            <section class="coupon_desc">
              <h4>{{item.name}}</h4>
              <span>有效期：{{item.liveDay}}~{{item.deadDay}}</span><br/>
              <span>使用说明：优惠券只能抵扣服务费部分，服务费满{{item.meetAmount}}使用，一单仅限使用一张。仅限在有效期内使用，过期作废。</span>
            </section>   
          </div>

       </div>

     </van-list>

    </van-row>
   

    <BottomNav @change-cartNum="cartNum=$event"></BottomNav>



  </div>
</template>

<script>
import {Toast} from 'vant'
import BottomNav from 'components/BottomNav'

export default {
  components: {
      BottomNav
  },
  data: () => ({
   coupon: {status: 0},
   coupons: [],
   finished: false,
   loading: false,
   pageNum: 1,
   result: null

  }),
  methods: {
   
    goBack(){
      this.$router.go(-1);
    },
    mycoupon(){
      let url = '/customer/main/mycoupon';
      this.coupon.pageNum = this.pageNum;
      this.loading = true;
      this.$axios.post(url, this.coupon).then(resp => {
        if(resp.data.status == this.STATUS_SUCCESS){
          let list = resp.data.data.list;

          if(list.length == 0){
            if(this.pageNum == 1){
              this.result = "您没有优惠券";
            }else{
              this.result = "没有更多了";
            }
            this.finished = true;
          }else{
            for(let i=0;i<list.length;i++){
              this.coupon.push(list[i]);
            }
            if(list.length < 40){
              this.result = "没有更多了";
              this.finished = true;
            }else{
              this.pageNum ++;
            }
          }


          
        }else{
          Toast(resp.data.message);
          this.finished = true;
        }
        this.loading = false;
      });
    },
    chgStatus(){
      // 清空原数据
      this.coupons = [];
      this.pageNum = 1;
      this.mycoupon();
    }
   

  },
 
  mounted: function () {
    this.mycoupon();    


  },
};







</script>

<style scoped>

  .coupon{
    width: 90%;
    height: 100px;
    margin: 3px auto;
    border-radius: 10px ;
    border: 1px solid #cccccc;
    background: linear-gradient(to right,  #ff5a24,#ff6034);
    color: #ffffff;
    font-family: "微软雅黑";
  }
  .coupon_use{
    width: 90%;
    height: 100px;
    margin: 3px auto;
    border-radius: 10px ;
    border: 1px solid #cccccc;
    background: linear-gradient(to right,  #cccccc,#999999);
    color: #ffffff;
    font-family: "微软雅黑";
  }
  .coupon_price{
    width: 25%;
    height: 100px;
    line-height: 100px;
    font-size: 30px;
    text-align: center;
    float: left;
  }
  .coupon_desc{
    width: 75%;
    height: 100px;
    float: left;  
  }
  .coupon_desc h4{
    padding-left: 20%;
    font-weight: 900;
    margin: 5px auto;
    font-size: 16px;
  }
  .coupon_desc span{
    font-size: 12px;
  }

</style>