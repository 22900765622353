<template>
  <div class="content">


    <van-row>
      <van-nav-bar title="我" left-arrow @click-left="goBack()"/>

      <van-col span="24" >
        <van-cell-group style="height: 100%;min-height:750px">
          <van-cell title="个人信息"  is-link to="myinfo"/>
          <van-cell title="我的推广链接"  is-link to="mylink"/>
<!--          <van-cell title="优惠券"  is-link to="coupon"/>-->
        </van-cell-group>


      
      </van-col>

    </van-row>



    <BottomNav @change-cartNum="cartNum=$event"></BottomNav>



  </div>
</template>

<script>
import BottomNav from 'components/BottomNav'

export default {
  components: {
      BottomNav
  },
  data(){
    return {

    };
  },
  methods: {
   
    goBack(){
      this.$router.go(-1);
    },
   

  },
 
  mounted: function () {
    


  },
};







</script>

<style>
  .van-cell__title{

  }

</style>