<template>
  <div id="app">
    <van-row class="content">
      <van-col span="24" style="text-align: left;display: flex; align-items: center;">
        <img src="../assets/yikh_logo.png" class="logo"/>

        &nbsp; &nbsp;
        亿卡汇订单管理系统

      </van-col>

      <van-col span="24" >
        <div class="loginCol">
          <h3>登 录</h3>
          <van-form @submit="login">
            <van-field v-model.trim="customer.phone"  placeholder="请输入手机号码" :rules="[{ required: true, message: '请填写手机号码', validator: phoneValidator }]" />
            <van-field
                    v-model="customer.password"
                    center
                    clearable
                    placeholder="请输入短信验证码"
                    :rules="[{ required: true, message: '请填写短信验证码' }]"
            >
              <template #button>
                <van-button size="small" type="primary"  :disabled="sendCodeDisable" @click="sendCode()">{{sendCodeText}}</van-button>
              </template>
            </van-field>

            <div style="margin: 16px;">
              <van-button round block type="info" native-type="submit"> 登 录 </van-button>
            </div>
          </van-form>

        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>

import {Dialog} from 'vant'


export default {
  name: 'App',
  data(){
    return {
      customer: {},
      sendCodeDisable: false,
      sendCodeText: '发送短信',
      seconds: 60
    };
  },
  methods: {
    // 验证联系电话的合法性
    phoneValidator(val){
      if(!/^1[3456789]\d{9}$/.test(val)){
        return "请填写正确的手机号码";
      }
    },
    // 发送短信验证码
    sendCode(){
        let result = this.phoneValidator(this.customer.phone);
        if(result){
          Dialog({message: result });
          return;
        }

        this.sendCodeDisable = true;
        let url = "/customer/send-code?phone="+this.customer.phone;
        this.$axios.get(url).then(resp => {
          if(resp.data.status == this.SUCCESS){
            // 开始倒计时
            this.startCount();
          }else{
            Dialog.alert({
              message: resp.data.message
            });
            this.sendCodeDisable = false;
          }
        });
    },
    // 开启倒计时 60s
    startCount(){
      if(this.seconds > 0){
        this.sendCodeText = this.seconds-- +"后再次发送"
        let _this = this;
        setTimeout(function(){_this.startCount()}, 1000);
      }else{
        this.sendCodeDisable = false;
        this.sendCodeText = "发送验证码";
        this.seconds = 60;
      }

    },
    login(){
      let url = '/customer/login';
      this.$axios.post(url, this.customer).then(resp => {
        if(resp.data.status == this.SUCCESS){
          sessionStorage.setItem("account", resp.data.data.account);
          sessionStorage.setItem("type", resp.data.data.type);
          sessionStorage.setItem("title",resp.data.data.name);
          document.title = "欢迎"+sessionStorage.getItem("title");
          this.$router.push("/");
        }else{
          Dialog({message: resp.data.message });
        }
      })
    },
  }
 
}
</script>

<style scoped>


.logo{
  vertical-align: middle;
  width: 2.6rem;
}
.title{
  font-size: 0.3rem;
  margin-left: 0.1rem;
}
.loginCol{
  width: 6rem;
  box-shadow: 0.1rem 0.1rem 0.05rem 0.05rem #888888;
  margin-top: 0.6rem;
  border-radius: 0.2rem;
  margin: 0.3rem auto;
  background-color: #ffffff ;
  font-size: 0.3rem;
  border: 0.01rem solid #cccccc;
  padding: 0.3rem;
}
.loginCol h3{
  height: 0.6rem;
  line-height: 0.6rem;
  /* background: #FF9710; */
  border-radius: 0.2rem 0.2rem 0rem 0rem;
  /* color: #ffffff; */

  margin: 0;
  text-align: center;
}

.van-button--info {
    color: #fff;
    background-color: #FE9A15;
    border: 0.01rem solid #FE9A15;
}
</style>
