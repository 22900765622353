<template>
  <div class="content">


    <van-row>
      <van-nav-bar title="修改密码" left-arrow @click-left="goBack()"/>
      <van-col span="24" style="padding:10px; text-align:left; font-size: 14px;">
        <span class="pwdnote">温馨提示：密码必须由6-18位的数字、字母、特殊符号（% $ % # 等）组成。请不要轻易将密码泄露给别人！</span>
        <van-form @submit="updatePwd">
          <van-field v-model.trim="customer.password" type="password" name="旧密码" label="旧密码" placeholder="请输入旧密码" :rules="[{ required: true }]" />
          <van-field v-model.trim="customer.newPassword" type="password" name="新密码" label="新密码" placeholder="请输入新密码" :rules="[{validator: checkPwd,  message: '密码由6-18位的数字、字母、特殊符号组成'}]"/>
          <van-field v-model.trim="customer.nowPassword2" type="password" name="确认密码" label="确认密码" placeholder="请输入确认密码" :rules="[{ validator: checkPwd2, message: '确认密码不能为空并且必须和新密码一致'}]"/>
          
          <div style="margin: 16px;">
            <van-button round block type="warning" native-type="submit"> 确 定 修 改 密 码 </van-button>
          </div>
        </van-form>


      
      </van-col>

    </van-row>
   


  <BottomNav @change-cartNum="cartNum=$event"></BottomNav>


  </div>
</template>

<script>

import {Toast} from 'vant'
import {Dialog} from 'vant'
import BottomNav from 'components/BottomNav'

export default {
  components: {
      BottomNav
  },
  data: () => ({
   customer: {}
  }),
  methods: {
    checkPwd(){
      let pwd = this.customer.newPassword;
      if(!pwd){
        return false;
      }
      let reg = /^[\da-zA-Z~!@#$%^&*()]{6,18}$/;
      if(!reg.test(pwd)){
        return false;
      }
      return true;

    },
    checkPwd2(){
      let pwd = this.customer.newPassword;
      let pwd2 = this.customer.nowPassword2;
      if(!pwd2){
        return false;
      }
      if(pwd2 != pwd){
        return false;
      }

    },

   
    goBack(){
      this.$router.go(-1);
    },
    myinfo(){
      let url = '/customer/main/myinfo';
      this.$axios.post(url).then(resp => {
        if(resp.data.status == this.STATUS_SUCCESS){
          this.customer = resp.data.data;
        }else{
          Toast(resp.data.message);
        }
      });
    },
    updatePwd(){
      let url = '/customer/main/update-pwd';
      Dialog.confirm({message: "确定要修改密码吗？"}).then(() => {
        this.$axios.post(url, this.customer).then(resp => {
          if(resp.data.status == this.STATUS_SUCCESS){
            window.location.reload();
            Toast("修改成功");
          }else{
            Toast(resp.data.message);
          }
        });

        
      });
    }
   

  },
 
  mounted: function () {
    this.myinfo();


  },
};







</script>

<style>
  .pwdnote{
    display: inline-block;
    line-height: 30px;
    color:  #ED3908;
  }

</style>