<template>
  <div class="content">
    <van-nav-bar title="我的购物车"></van-nav-bar>

    <van-cell>
      <h4>购物车说明</h4>
      <p>
        添加到购物车的号码，仅仅为了方便您下次查找，不具有预留功能，所以该号码还是可以被别的买家下单哦，如果您喜欢，请尽快下单哦！^_^
      </p>
    </van-cell>
    
    <van-cell >
      <van-goods-action-button v-if="myCart.length != 0" type="warning" text="管 理" style="width:25%" @click="edit = !edit" />
      <van-goods-action-button v-if="edit && myCart.length != 0" type="danger" text="删 除 选 中" style="width:25%"  @click="removeSelect()" />
      <van-goods-action-button v-if="edit && myCart.length != 0" type="danger" text="清空" style="width:25%"  @click="removeAll()" />
    </van-cell>

    <van-cell>
      <!-- 购物车号码列表 -->
      <van-list style="margin-bottom: 76px;" v-model="loading" :finished="finished" :finished-text="result" @load="loadData">
        <van-collapse v-model="activeName"  accordion>
          <van-collapse-item  v-for="phone in myCart" :key="phone.phoneNumber" :name="phone.phoneNumber" :disabled="phone.area == null" >
            <template #title >
              <label  style="width:35%">
                <span>
                  {{phone.display}}
                </span>
              </label>
              <label v-if="phone.area != null" style="width:30%">{{phone.area}} 
                <span style="color: #ED3908">{{phone.optName}}</span>
              </label>
              <label v-else>
                已售出
              </label>
              <label v-if="phone.salePrice != null">￥{{phone.salePrice+phone.fee}}</label>
              
            </template>

            <div v-if="phone.area != null">
              <label>价&nbsp;&nbsp;格:</label>
              <span>
                <span class="red">{{phone.salePrice+phone.fee}}</span> 元 （服务费 <span class="red">{{phone.salePrice}}</span> 元 + 话费 <span class="red">{{phone.fee}}</span> 元） 
              </span>
              
              
              <br />
              <label>内含话费:</label>
              <span class="red">{{phone.fee}}</span>元
              
              <br />
              <label>介绍:</label>
            
              <span class="red">{{phone.introduce}}</span>
          
              <br />
              <br />
              
              <van-goods-action-button type="danger" text="立 即 购 买" @click="buy(phone)" />
              
            </div>
          </van-collapse-item>
        </van-collapse>
        
      </van-list>

    </van-cell>
 


  <BottomNav @change-cartNum="cartNum=$event"></BottomNav>
  </div>
</template>

<script>

import {Dialog} from 'vant';
import { Toast } from 'vant';
import BottomNav from '../components/BottomNav'

export default {
  components: {
      BottomNav
  },
  data(){
    return {
      result: "",
      finished: false,
      myCart: [], // 我的购物车中的商品
      currentPage: 1, // 当前页
      loading: false,
      activeName: "",
      phoneNumberClass: ["","","","red","red","red","red","","","",""],
      edit: false,
      removePhones:[], // 要删除的号码
    };
  },
  methods: {
    // 删除选中号码
    removeSelect(){
      this.removePhones = [];
      this.myCart.forEach(item => {
        if(item.status){
          this.removePhones.push(item.phoneNumber);
        }
      });
      if(this.removePhones.length == 0){
        return;
      }
      Dialog.confirm({
        message: '确定要删除选中号码吗？',
      }).then(() => {
          let url = "/customer/main/cart/remove-select";
          this.$axios.post(url, this.removePhones).then(resp => {
            if(resp.data.status == this.STATUS_SUCCESS){
              Toast('删除成功');
              this.reload();
            }else{
              Dialog({message: resp.data.message});
            }
          });
      }).catch(() => {});
    },
    removeAll(){
      if(this.myCart.length == 0){
        return;
      }
      Dialog.confirm({
        message: '确定要清空购物车中的号码吗？',
      }).then(() => {
          let url = "/customer/main/cart/remove-all";
          this.$axios.post(url).then(resp => {
            if(resp.data.status == this.STATUS_SUCCESS){
              Toast('清空成功');
              this.reload();
            }else{
              Dialog({message: resp.data.message});
            }
          });
      }).catch(() => {});
    },
    // 立即购买
    buy(phone){
      this.$router.push({path: "/buy", query: {phoneNumber: phone.phoneNumber}});
    },
    initCartNum(){
      // 查询购物车中添加的号码数量
      let url = "/customer/main/cart/my-cart-num";
      this.$axios.get(url).then(resp => {
        this.$emit("change-cartNum", resp.data.data); 
      });
    },
    loadData(){
      this.loading = true;
      let url = "/customer/main/cart/mycart?pageNum="+this.currentPage;
      this.$axios.get(url).then(resp=>{
        if(resp.data.status == this.STATUS_SUCCESS){
          let list = resp.data.list;
          if(list.length == 0){
            if(this.currentPage == 1){
              this.result = "您的购物车是空的";
            }else{
              this.result = "没有更多了";
            }
            this.finished = true;
          }else{

            for(let i=0;i<list.length;i++){
              this.myCart.push(list[i]);
            }
            if(list.length < 40){
              this.result = "没有更多了";
              this.finished = true;
            }else{
             this.currentPage ++;
            }
           
          }
        }else{
          this.finished = true;
          this.result = resp.data.message;
        }
       
        this.loading = false;
      });
    },
    reload(){
       this.initCartNum();
       this.myCart = [];
       this.currentPage = 1;
       this.finished = false;
       this.loading();

    },
    stopOpen: function(e){
      e.stopPropagation()
    }
   
  },
  mounted: function () {
   
  },
 
}
</script>


<style scoped>
  p{
    text-indent: 20px;
  }
  .red{
    color: #ED3908;
  }
  .blue{
    color: #039BE5;
  }




</style>
