<template>
  <div class="content">
    <van-nav-bar title="我的订单"></van-nav-bar>

    <van-form @submit="loadData(1)" style="margin-top: 0.05rem; margin-bottom: 0.05rem">
      <van-cell-group inset>
        <van-field
                v-model="queryObj.phoneNumber"
                name="订购号码"
                label="订购号码"
                type="tel"
                placeholder="请输入订购号码"
        />
        <van-field
                v-model="queryObj.name"
                name="客户姓名"
                label="客户姓名"
                placeholder="请输入客户姓名"
        />
        <van-field
                v-model="queryObj.phone"
                name="客户联系电话"
                label="客户电话"
                type="tel"
                placeholder="请输入客户联系电话"
        />
        <van-field
                v-model="statusText"
                is-link
                readonly
                name="picker"
                label="订单状态"
                placeholder="点击选择订单状态"
                @click="showStatus = true"
        />
        <van-popup v-model:show="showStatus" position="bottom">
          <van-picker
                  :columns="statuses"
                  @confirm="confirmStatus"
                  @cancel="showStatus = false"
          />
        </van-popup>

		  <van-field
			  v-model="customerText"
			  is-link
			  readonly
			  name="picker"
			  label="订单来源"
			  placeholder="点击选择订单状态"
			  @click="showCustomer = true"
		  />
		  <van-popup v-model:show="showCustomer" position="bottom">
			  <van-picker
				  :columns="customers"
				  @confirm="confirmCustomer"
				  @cancel="showCustomer = false"
				  :columns-field-names="{text: 'name', value: 'id'}"
			  />
		  </van-popup>


        <van-field
                v-model="queryObj.startDay"
                is-link
                readonly
                label="起始日期"
                placeholder="请选择起始日期"
                @click="showStartDay = true"
        />

        <van-field
                v-model="queryObj.endDay"
                is-link
                readonly
                label="结束日期"
                placeholder="请选择结束日期"
                @click="showEndDay = true"
        />
      </van-cell-group>
      <div class="btn_div">
        <van-button plain type="primary" icon="search"   @click="loadData(1)" :disabled="btnDisabled">查询订单</van-button>
		<van-button plain type="success" icon="down"  @click="exportOrder()" :disabled="btnDisabled">订单导出</van-button>
		<van-button plain type="danger" icon="bar-chart-o"  @click="orderReport()" :disabled="btnDisabled">订单统计</van-button>
      </div>
    </van-form>
	<!-- 订单统计显示 弹出层 -->
	  <van-popup v-model:show="showReport" position="top" :style="{ height: '60%' }" >

		  <table class="report-table">
			  <tr align="center">
				  <td colspan="3">订单统计</td>
			  </tr>
			  <tr class="report-head">
				  <td>状态</td>
				  <td>数量</td>
				  <td>占比</td>
			  </tr>
			  <tr v-for="item in reportItems" :key="'tr_'+item.itemName">
				  <td>{{item.itemName}}</td>
				  <td>{{item.num}}</td>
				  <td>{{item.rate}}</td>
			  </tr>
		  </table>
	  </van-popup>

    <!--起始日期 -->
    <van-popup v-model:show="showStartDay" position="bottom">
      <van-datetime-picker v-model="startDay" type="date" title="选择起始年月日" :min-date="minDate" :max-date="today"  @confirm="confirmStartDay" @cancel="cancelStartDay" cancel-button-text="清空"/>
    </van-popup>

    <!-- 结束日期  -->
    <van-popup v-model:show="showEndDay" position="bottom">
      <van-datetime-picker v-model="endDay" type="date" title="选择起始年月日" :min-date="minDate"  :max-date="today" @confirm="confirmEndDay" @cancel="cancelEndDay" cancel-button-text="清空"/>
    </van-popup>

    <van-cell>
      <!-- 订单列表 -->
      <van-list style="margin-bottom: 1.56rem;"  v-model="loading" :finished="finished" :finished-text="result" @load="loadData">
		  <article v-for="order in myorders" :key="order.id" :name="order.id+'_'+order.type" class="my_order_art">
			  <div class="my_order_item">
				  <label class="order_item_title">订购号码</label>
				  <span class="order_item_content">
					  <label v-html="order.phoneNumberDisplay" style="width: 1.8rem"></label>
						&nbsp;
					  <span  v-if="order.optId == 1">移动</span>
					  <span  v-else-if="order.optId == 2">联通</span>
					  <span  v-else-if="order.optId == 3">电信</span>
					  <span  v-else-if="order.optId == 4">虚商</span>
						&nbsp;
					  {{order.phoneProvince}}
					  &nbsp;
					  {{order.phoneArea}}

				  </span>
			  </div>
			  <div class="my_order_item">
				  <label class="order_item_title">订单编号</label>
				  <span class="order_item_content">
					  {{order.orderNo}}
				  </span>
			  </div>
			  <div class="my_order_item">
				  <label class="order_item_title">下单时间</label>
				  <span class="order_item_content">
					  {{order.createTime}}
				  </span>
			  </div>
			  <div class="my_order_item">
				  <label class="order_item_title">订单来源</label>
				  <span class="order_item_content">
					  {{order.customerName}}
				  </span>
			  </div>
			  <div class="my_order_item">
				  <label class="order_item_title">客户资料</label>
				  <span class="order_item_content">
					  {{order.name}} &nbsp;
					  {{order.phone}} &nbsp;
					  {{order.idcard}} &nbsp;<br/>
					  {{order.province}}
					  {{order.city}}
					  {{order.area}}
					  {{order.address}}
				  </span>
			  </div>
			  <div class="my_order_item">
				  <label class="order_item_title">物流信息</label>
				  <span class="order_item_content">
					  {{order.expressCompany}} {{order.expressNo}}
				  </span>
			  </div>
			  <div class="my_order_item">
				  <label class="order_item_title">订单状态</label>
				  <span class="order_item_content">
					  <span v-if="order.statusText == '退单'" class="red">{{order.statusText}}</span>
					  <span v-else-if="order.statusText == '激活'" class="green">{{order.statusText}}</span>
					  <span v-else-if="order.statusText == '已发货'" class="blue">{{order.statusText}}</span>
					  <span v-else>{{order.statusText}}</span>


				  </span>
			  </div>

			  <div class="my_order_item">
				  <label class="order_item_title">订单备注</label>
				  <span class="order_item_content red">
					  {{order.remark}}
					  <span v-if="order.reason != null && order.reason != ''">【{{order.reason}}】</span>
				  </span>
			  </div>
		  </article>

        
      </van-list>

    </van-cell>

    <BottomNav @change-cartNum="cartNum=$event"></BottomNav>



  </div>
</template>

<script>

import {Dialog} from 'vant';
import XLSX from "xlsx";
import { Toast } from 'vant';
import BottomNav from '../components/BottomNav'

export default {
  components: {
      BottomNav
  },
  data(){
    return {
      result: "",
      finished: false,
      myorders: [], // 我的购物车中的商品
      currentPage: 1, // 当前页
      loading: false,
      activeName: "",
      queryObj: {pageNum: 1},
      active: 0,
      showStatus: false,
      showStartDay: false,
      showEndDay: false,
      statusText: '',
      today: new Date(),
      startDay: new Date(),
      endDay: new Date(),
      statuses: [
          {value: '-1', text: "全部"},
          {value: '1', text: "待处理"},
          {value: '2', text: "已下单"},
          {value: '3', text: "已发货"},
          {value: '4', text: "激活"},
          {value: '5', text: "退单"},
          {value: '6', text: "下单失败"}
          ],
		btnDisabled: false,
		showCustomer: false,
		customers: [],
		customerText: '',
		showReport: false,
		reportItems: []
    };
  },
  computed: {
    // 最早日期是90天前
    minDate(){
      // 返回1970年1月1日到现在的时间毫秒数
      let time = new Date().getTime()-3*30*24*60*60*1000;
      let a = new Date(time);
      return a;
    }
  },
  methods: {
	  // 订单统计
	  orderReport(){
		  let url = "/customer/main/order/order-report";
		  this.btnDisabled = true;
		  this.$axios.post(url, this.queryObj).then(resp=>{
			  let list = resp.data.list;
			  let total = 0;
			  for(let i=0;i<list.length;i++){
				 total += list[i].num;
			  }
			  this.reportItems = [];
			  this.reportItems.push({
				  itemName: '总订单量',
				  num: total,
				  rate: '100%'
			  });
			  if(total != 0){
				  for(let i=0;i<list.length;i++){
					  this.reportItems.push({
						  itemName: list[i].itemName,
						  num: list[i].num,
						  rate: Math.round(list[i].num*10000/total)/100+'%'
					  });
				  }
			  }
			  this.showReport = true;
		  }).finally(()=>{
			  this.btnDisabled = false;
		  })
	  },

	  // 订单导出
    exportOrder(){
      let url = "/customer/main/order/export-order";
	  this.btnDisabled = true;
      this.$axios.post(url, this.queryObj).then(resp=>{
        if(resp.data.status == this.SUCCESS){
          let data = [[]];
          let flag = true;
          resp.data.list.forEach(item => {
            let obj = [];
            for(let key in item){
              if(flag)(data[0].push(key));
              obj.push(item[key]);
            }
            data.push(obj);
            flag = false;
          });
          // 导出为Excel
          const ws = XLSX.utils.aoa_to_sheet(data);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          XLSX.writeFile(wb, "order.xlsx");
        }else {
          Dialog.alert({
              message: '订单导出失败！'
          })
        }
      }).finally(()=>{
		  this.btnDisabled = false;
	  })
    },
    loadData(pageNum){
      // 如果上一次还没加载完成，停止下一次加载
      if(this.loading){
        return;
      }
      this.loading = true;

      if(pageNum){
        this.currentPage = pageNum;
      }

      if(this.currentPage == 1){
        this.myorders = [];
      }
      let url = "/customer/main/order/myorder";
      this.queryObj.pageNum = this.currentPage;

      this.$axios.post(url, this.queryObj).then(resp=>{
        if(resp.data.status == this.SUCCESS){
          let list = resp.data.list;

          if(!list || list.length == 0){
            if(this.currentPage == 1){
              this.result = "您没有符合条件的订单";
            }else{
              this.result = '仅展示近90天内的订单，更早订单请咨询客服';
            }
            this.finished = true;
          }else{
            for(let i=0;i<list.length;i++){
              this.myorders.push(list[i]);
            }
            if(list.length < 40){
              this.result = '仅展示近90天内的订单，更早订单请咨询客服';
              this.finished = true;
            }else{
               this.currentPage ++;
            }

            this.loading = false;
          }
        }else{
          this.finished = true;
          Toast(resp.data.message);
        }
        this.loading = false;
      });
    },

    confirmStatus(val){
      if(val.value == -1){
        this.statusText = '';
      }else{
        this.statusText = val.text;
      }
      this.queryObj.status = val.value;
      this.showStatus = false;
      this.loadData(1);
    },
  	confirmCustomer(val){
		if(val.id == -1){
			this.customerText = '';
			this.queryObj.customerId = null;
		}else{
			this.customerText = val.name;
			this.queryObj.customerId = val.id;
		}

		this.showCustomer = false;
		this.loadData(1);
  	},
    // 确认起始日期
    confirmStartDay(val){
      this.queryObj.startDay = this.formatDate(val);
      this.showStartDay = false;

      this.loadData(1);
    },
    // 确认截止日期
    confirmEndDay(val){
      this.queryObj.endDay = this.formatDate(val);
      this.showEndDay = false;
      this.loadData(1);
    },
    // 清空起始日期
    cancelStartDay(){
      this.queryObj.startDay = null;
      this.showStartDay = false;
      this.loadData(1);
    },
    // 清空截止日期
    cancelEndDay(){
      this.queryObj.endDay = null;
      this.showEndDay = false;
      this.loadData(1);
    },

    formatDate(date){
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      return year+"-"+(month<10?'0'+month:month)+"-"+(day<10?'0'+day:day);
    },

    stopOpen: function(e){
      e.stopPropagation()
    },


   
  },
  mounted: function () {

    // // 查询购物车中添加的号码数量
    // let url = "/customer/main/cart/my-cart-num";
    // this.$axios.get(url).then(resp => {
    //   this.$emit("change-cartNum", resp.data.data);
    //   // this.loadData();
    // });

    
    this.loadData(1);

	let url = '/customer/main/order/my-customers';
	this.$axios.post(url).then(resp=>{

		this.customers = resp.data.list;
		this.customers.unshift({id: -1, name: '全部'})

	});



  },
 
}
</script>


<style scoped>
.report-table{
	width: 7rem;
	margin: 0.5rem auto;
	border: 0.01rem solid #010101;
	border-collapse:collapse;
}
.report-table tr, .report-table td{
	border: 0.01rem solid #010101;
	padding: 0.05rem;
}
.report-head{
	background: #c0c0c0;
}


	.btn_div{
		margin-top: 0.05rem;
		display: flex;
	}
	.btn_div button{
		margin-left: 0.2rem;
	}
  p{
    text-indent: 0.2rem;
  }
  .my_order_art{
	  padding-bottom: 0.1rem;
	  padding-top: 0.1rem;
	  border-bottom: 0.15rem solid #F2F6FC;
  }
  .my_order_item{
	  display: flex;
	  justify-content: space-between;

  }
  .order_item_title{
	  text-align: left;
	  font-weight: bold;
	  white-space: nowrap;
	  margin-right: 0.2rem;
  }
  .order_item_content{
	  text-align: right;
  }

  .queryCond{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .table{
    border-radius: 0.25rem;
    border: 0.01rem lightgray solid;
    padding: 0.2rem;
    font-size: 0.26rem;
  }
  .table p{
    display: flex;
    justify-content: center;
    margin-top: 0.2rem;
  }
  .table p label{
    display: block;
    width: 2.4rem;
    font-weight: bold;
  }
  .table p span{
    display: block;
    width: 5rem;
  }




</style>
