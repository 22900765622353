<template>
  <div id="app">
    自动登录中，稍后会自动进入首页。。。
  </div>
</template>

<script>



export default {
  name: 'App',
  data(){
    return {
      
    };
  },
  mounted(){
    let customer = this.$route.params.customer;
    if(customer){
      let url = '/customer/login-';

      let params = {account: customer}
      this.$axios.post(url,params).then(resp => {
        if(resp.data.status == this.SUCCESS){
          sessionStorage.setItem("title",resp.data.data.name);
          sessionStorage.setItem("type", resp.data.data.type);
          sessionStorage.setItem("account", resp.data.data.account);

          document.title = "欢迎"+sessionStorage.getItem("title");
          let vue = this;
          vue.$router.push("/");
        }else{
          this.$router.push("/login");
        }
      });
      console.log("done");
    }else{
      this.$router.push("/login");
    }
  }
 
}
</script>

<style>
  #app{
    font-size: 0.28rem;
  }
</style>


