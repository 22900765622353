<template>
  <div class="content">


    <van-row>
      <van-nav-bar title="个人信息" left-arrow @click-left="goBack()"/>
      <van-col span="24" >
        <van-cell-group style="height: 100%;min-height:750px" v-if="customer.type == 2">
          <van-cell title="名字"  :value="customer.name"/>
          <van-cell title="店铺编号"  :value="customer.shopNo"/>
          <van-cell title="绑定手机"  :value="customer.phone"/>
          <van-cell title="联系人"  :value="customer.contactName"/>
          <van-cell title="店铺位置"  :value="customer.province+' '+customer.city+' '+customer.district+' '+customer.address"/>

        </van-cell-group>


      
      </van-col>

    </van-row>
   

    <BottomNav @change-cartNum="cartNum=$event"></BottomNav>



  </div>
</template>

<script>

import {Toast} from 'vant'

import BottomNav from 'components/BottomNav'

export default {
  components: {
      BottomNav
  },
  data: () => ({
   customer: {}
  }),
  methods: {
   
    goBack(){
      this.$router.go(-1);
    },
    myinfo(){
      let url = '/customer/main/myinfo';
      this.$axios.post(url).then(resp => {
        if(resp.data.status == this.SUCCESS){
          this.customer = resp.data.data;
        }else{
          Toast(resp.data.message);
        }
      });
    }
   

  },
 
  mounted: function () {
    this.myinfo();


  },
};







</script>

<style>


</style>