<template>
  <div>
    <div id="app"></div>
    <router-view ></router-view>

    <div class="goTop" @click="backtop">
      <van-icon name="back-top" />
    </div>

    <footer class="footer" v-if="$route.path == '/login'">

      <a>版权所有：杭州我爱号码网电子商务有限公司</a>
      <a>公司地址：浙江省杭州市拱墅区兴业街16号物联网产业园6幢422室</a>
      <a>联系电话：0571-28888520</a>

      <a target="_blank" href="http://www.beian.gov.cn" >
        <img src="./assets/gaba.png" />
        <span >浙公网安备 33010502006969号</span >
      </a>
      <a href="https://beian.miit.gov.cn" target="_blank" >浙ICP备19034816号-2</a>

    </footer>
    



  </div>
</template>

<script>


export default {
  name: 'App',
  data(){
    return {
      cartNum: 0
    };
  },
  methods: {
    backtop(){
      var timer = setInterval(function(){
        let osTop = document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
        this.isTop = true;
        if(osTop === 0){
          clearInterval(timer);
        }
      },30)
    }


  },
  mounted() {
    
    
  }

 
}
</script>

<style >
  @media (min-width: 320px){html{font-size: 42.6667px;} }
  @media (min-width: 360px){html{font-size: 48px;} }
  @media (min-width: 375px){html{font-size: 50px;} }
  @media (min-width: 384px){html{font-size: 51.2px;} }
  @media (min-width: 414px){html{font-size: 55.2px;} }
  @media (min-width: 448px){html{font-size: 59.7333px;} }
  @media (min-width: 480px){html{font-size: 48px;} }
  @media (min-width: 512px){html{font-size: 68.2667px;} }
  @media (min-width: 544px){html{font-size: 72.5333px;} }
  @media (min-width: 576px){html{font-size: 76.8px;} }
  @media (min-width: 608px){html{font-size: 81.0667px;} }
  @media (min-width: 640px){html{font-size: 85.3333px;} }
  @media (min-width: 750px){html{font-size: 100px;} }

  *{
    margin: 0;
    padding: 0;

  }
  body{
    width: 7.5rem;
    margin: 0 auto !important;
	  background-color: #F2F6FC;
  }
  .red{
    color: #ed1c24;
  }
  .green{
    color: #34bf49;
  }
  .blue{
    color: #1989FA;
  }
  .sendGoods{
    color: #7552cc;
  }
  .footer{
    position:absolute;
    width: 7.5rem;
    height: 1.8rem;
    text-align: center;
    bottom: 0.2rem;
    font-size: 0.25rem;
    display:flex;
    flex-direction: column;

  }
  .footer a{
    height: 0.35rem;
    line-height: 0.35rem;
  }

  .goTop{
    position: fixed !important;
    left: 0.1rem;
    bottom: 2rem;
    z-index: 999;
    font-size: 0.5rem;
    width: 1rem;
    height: 1rem;
    line-height: 1rem;
    text-align: center;
    background: #1989FA;
    border-radius: 1rem;
    opacity: 0.2;
  }

</style>
