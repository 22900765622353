<template>
    <div>
      <section style="position: fixed; bottom: 0;width: 7.5rem; height: 1.56rem; background: #ffffff; z-index: 99">
        <van-grid  :column-num="4">
          <van-grid-item icon="wap-home" text="靓号" to="/"/>
          <!--                <van-grid-item icon="cart" text="购物车" to="/mycart" :badge="cartNum"/>-->
          <van-grid-item icon="bag" text="订单" to="/myorder" />
          <van-grid-item icon="bag-o" text="兰兰卡" to="/llcardorder" />
          <van-grid-item icon="friends" text="我" to="/me"/>
        </van-grid>
      </section>

    </div>

</template>
<script>
export default {
  data(){
    return {
      cartNum: 0
    };
  },
  methods: {
    
  },
  mounted() {
      // 查询购物车中添加的号码数量
      let url = "/customer/main/cart/my-cart-num";
      this.$axios.get(url).then(resp => {
        // this.$emit("change-cartNum", resp.data.data); 
        this.cartNum = resp.data.data;
      });
  }

}
</script>